/* eslint-disable react/jsx-key */
import { StructuredData } from 'layouts/structuredData';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useSiteMetadata } from '../hooks/use-site-metadata';

type Props = {
  title?: string;
  description?: string;
  pathname?: string;
};

export const SEO = ({ title, description, pathname }: Props) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ''}`,
    ogImg: `${siteUrl}/images/ogImage2.jpg`,
  };

  return (
    <>
      {headData(seo).map((item, index) => (
        <Helmet key={index}>{item}</Helmet>
      ))}
    </>
  );
};

export const headData = (seoData: { [k: string]: string }) => {
  // Function that replaces/updates the existing meta tags
  const replaceMetaTag = (
    name: string,
    attributeName: string,
    attributeValue: string,
  ) => {
    const element = document.querySelector(`meta[${attributeName}="${name}"]`);
    if (element && attributeValue) {
      element.setAttribute('content', attributeValue);
    }
  };

  React.useEffect(() => {
    replaceMetaTag('title', 'id', seoData.title);
    replaceMetaTag('description', 'id', seoData.description);
    replaceMetaTag('image', 'name', seoData.image);
    replaceMetaTag('og:image', 'property', seoData.ogImg);
    replaceMetaTag('og:title', 'property', seoData.title);
    replaceMetaTag('og:description', 'property', seoData.description);
    replaceMetaTag('twitter:title', 'property', seoData.title);
    replaceMetaTag('twitter:url', 'property', seoData.url);
    replaceMetaTag('twitter:description', 'property', seoData.description);
    replaceMetaTag('twitter:image', 'property', seoData.ogImg);
  }, [seoData]);

  return [
    <title id="title">{seoData.title}</title>,
    <meta id="description" name="description" content={seoData.description} />,
    <meta name="image" content={seoData.image} />,
    <meta property="og:image" content={seoData.ogImg} />,

    <meta property="og:title" content={seoData.title} />,
    <meta property="og:description" content={seoData.description} />,

    <meta name="twitter:card" content="summary_large_image" />,
    <meta property="twitter:title" content={seoData.title} />,
    <meta property="twitter:url" content={seoData.url} />,
    <meta property="twitter:description" content={seoData.description} />,
    <meta property="twitter:image" content={seoData.ogImg} />,

    <meta charSet="utf-8" />,
    <meta
      name="theme-color"
      content="linear-gradient(86.32deg, #e5e4e2 11.03%, #f0f0f0 77.7%)"
    />,
    <meta httpEquiv="x-ua-compatible" content="ie=edge" />,
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />,
    <meta
      name="robots"
      content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
    />,
    <StructuredData
      data={{
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: seoData.title,
        url: seoData.url,
        logo: seoData.image,
      }}
    />,
    <script
      id="CookieConsent"
      src="https://policy.app.cookieinformation.com/uc.js"
      data-culture="EN"
      type="text/javascript"
    ></script>,
  ];
};
